// font family
$main-font: "Montserrat", sans-serif;


// bg
$main-bg:  #fcfcfc;

// text
$text: #003436;


// transition
$transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

$white: #fcfcfc;
$black: #003436;
$accent: #016d62;
$second: #003436;


$error: #9b0a00;

// modal
$modal-bgd: #5f5f5f;
$burger-btn-stroke: #363636;
