/* Стили для модального окна */
.modal {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    display: flex;
    align-items: flex-end;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 10;
}


.modal.active {
    opacity: 1;
    visibility: visible;
}

/* Контент модального окна */
.modal-content {
    position: relative;
    background: $black;
    padding: 40px 20px;
    color: $white;

    width: 100%;
    max-width: 100%;
    width: 100%;

    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 20px;


    @include desktop{
        padding: 40px 60px;
    }

        .container {
            display: flex;
            flex-direction: column;
            gap: 32px;
    
            @include desktop {
                flex-direction: row;
            }
        }
}



.btn-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px   ;

    .btn{
        font-family: inherit;
        background: $accent;
        width: 100%;
        max-width: 100%;
        margin-left: auto;
        @include tablet{
            max-width: max-content;
        }
    }
}



/* Кнопка закрытия */
.close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 44px;
    cursor: pointer;
}