.form{
    
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 590px;

    margin: 0 auto;
    background: transparent;
    @include desktop{
        margin: 0;
    }
    

    &-label{
        display: inline-block;
        width: 100%;

    }

    &-input{
        width: 100%;

     border: 1px solid #016d62;
        border-radius: 20px;
            padding: 15px 30px;
        font-family: inherit;
        font-weight: 400;
        font-size: 14px;
        background: rgba(214, 214, 214, 0.2);

      

        color: $white;


        &::placeholder{
            color: #b6b6b6;
        }

    
    }

    .textarea{
        min-height: 150px;
        border-radius: 26px;
        resize: none;
    }
    .btn{
        margin-top: 20px;

        max-width: 100%;
    }
}