html,
body {

  font-family: $main-font;
  background: $main-bg;

  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: $text;

  margin: 0 auto;
  width: 100%;

  scroll-behavior: smooth;
  // overflow-x: hidden;
}

section {

  padding-top: 40px;
  padding-bottom: 40px;
  @include desktop{
    padding-top: 80px;
    padding-bottom:80px;
  }
  

}



// ------------------------------------------  logo start  ------------------------------------------
.logo { 
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 800;
  font-size: 16px;
  line-height: 1;
  color: $white;
  text-transform: uppercase;
  transition: $transition;
  max-width: max-content;
  &:hover,
  &:focus {
    color: $accent;
  }

  &:active {
    color: inherit;
  }

 
  
}

// ------------------------------------------  logo end  ------------------------------------------

// ------------------------------------------  links start  ------------------------------------------

.link {
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  background-color: $white;
  border-radius: 49px;
  padding: 8px 12px;
  font-weight: 500;
  font-size: 14px;
  
  text-align: center;

  transition: $transition;

  width: 100%;
  max-width: max-content;
  &:hover,
  &:focus {
    color: $white;
    background: $accent;
  }

  &:active {
    color: inherit;
    background: $white;
  }

  &.active{
    font-weight: 600;
    background: $accent;
    color: $white;
  }

}



// ------------------------------------------  links end  ------------------------------------------

// ------------------------------------------  buttons start  ------------------------------------------
.btn {
  font-family: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
    max-width: 360px;

  border: none;
  border-radius: 60px;
  padding: 24px 72px;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;

 

  color: $white;
  background: $accent;
  cursor: pointer;

  &-second{
    background-color: #003436;
    color: #b2e0ef;
  }

  &.link-btn{
    text-decoration: underline;
  }
}





// ------------------------------------------  buttons end  ------------------------------------------

// ------------------------------------------  hover focus active start  ------------------------------------------
.hfa-scale {
  transition: $transition;

  &:hover,
  &:focus {
    transform: scale(1.021121);
  }

  &:active {}

  transform: scale(1);
}

// ------------------------------------------  hover focus active end  ------------------------------------------

// ------------------------------------------  titles start  ------------------------------------------

.title1 {

  font-size: 32px;
  line-height: 1.2;
  text-transform: uppercase;
  color: $white;

}

.title2 {

  font-size: 32px;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;

}

.title3{
  font-weight: 900;
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;
  color: inherit;
}
.title4{
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  color: inherit;
}

    .descr {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 54px;
    }


.cookie-title{
  font-weight: 700;
    font-size: 30px;
    line-height: 1.2;
}


// ------------------------------------------  titles end  ------------------------------------------



// ------------------------------------------  other styles start  ------------------------------------------


.disc {
  list-style: disc;
  padding-left: 20px;
}

.decimal {
  list-style: decimal;
  padding-left: 20px;
}


.arrow-up {
  position: fixed;
  bottom: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 30%;
  color: $accent;
  transition: $transition;
  cursor: pointer;

  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  &:hover,
  &:focus {
    color: $second;
    transform: scale(1.03112);
  }

  &:active {
    color: $accent;
    transform: inherit;
  }

  svg {
    width: 3.5rem;
    height: 3.5rem;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

// ------------------------------------------  other styles end ------------------------------------------



// ------------------------------------------  margins start ------------------------------------------


// ------------------------------------------  margins end --------------------------------------------




// ------------------------------------------  form validate styles start ------------------------------------------
.just-validate-error-field.form-input {
  border: 1px solid $error
}

.just-validate-error-label {

  display: none;
}

// ------------------------------------------  form validate styles start ------------------------------------------