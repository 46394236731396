.footer{
    padding-top: 80px;
    padding-bottom: 80px;
    background: #003436;
    color: $white;

    .content-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
       @include desktop{
        align-items: stretch;
       }
    }

    .navigation{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 32px;
        text-align: center;
        @include desktop{
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }

        .logo{
            align-self: center;
            @include desktop{
                align-self: flex-start;
            }
        }
        ul{
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            align-items: center;
            @include desktop{
                justify-content: space-between;
                align-items: flex-end;
            }
        }
        .link{
            background: transparent;
            color: $white;
        }
    }

  
  .lower{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include desktop{
        flex-direction: row;
        justify-content: space-between;

    }
    ul{
        display: flex;
        flex-direction: row;
        gap: 30px;
    }
  }
  
}
    

