 .header {
  
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    background: transparent;
    transition: $transition;


    &.fixed {        
        z-index: 5;
        background: $second;
        -webkit-box-shadow: 0px 3px 15px 0px rgba(4, 4, 4, 0.75);
        -moz-box-shadow: 0px 3px 15px 0px rgba(4, 4, 4, 0.75);
        box-shadow: 0px 3px 15px 0px rgba(4, 4, 4, 0.75);
      
       
    }

    .navigation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        padding-top:17px;
        padding-bottom: 17px;
    }

  
    ul{
        display: none;
        @include desktop{
            display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4px;
        }
        
    }

    .open-btn{
        margin: 0;
        padding: 0;
        background: transparent;
        border: none;
        cursor: pointer;
        @include desktop{
            display: none;
        }
    }
}