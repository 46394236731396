@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
}
@media screen and (max-width: 767.98px) {
  .container {
    max-width: 430px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    padding: 0 40px;
    max-width: 1200px;
  }
}

.is-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: currentColor;
  display: block;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.link {
  text-decoration: none;
}

.list {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

svg {
  fill: currentColor;
}

li:last-child {
  margin-bottom: 0 !important;
}

address {
  font-style: normal;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html,
body {
  font-family: "Montserrat", sans-serif;
  background: #fcfcfc;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #003436;
  margin: 0 auto;
  width: 100%;
  scroll-behavior: smooth;
}

section {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media screen and (min-width: 1200px) {
  section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.logo {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 800;
  font-size: 16px;
  line-height: 1;
  color: #fcfcfc;
  text-transform: uppercase;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  max-width: max-content;
}
.logo:hover, .logo:focus {
  color: #016d62;
}
.logo:active {
  color: inherit;
}

.link {
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  background-color: #fcfcfc;
  border-radius: 49px;
  padding: 8px 12px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  width: 100%;
  max-width: max-content;
}
.link:hover, .link:focus {
  color: #fcfcfc;
  background: #016d62;
}
.link:active {
  color: inherit;
  background: #fcfcfc;
}
.link.active {
  font-weight: 600;
  background: #016d62;
  color: #fcfcfc;
}

.btn {
  font-family: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 360px;
  border: none;
  border-radius: 60px;
  padding: 24px 72px;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  color: #fcfcfc;
  background: #016d62;
  cursor: pointer;
}
.btn-second {
  background-color: #003436;
  color: #b2e0ef;
}
.btn.link-btn {
  text-decoration: underline;
}

.hfa-scale {
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transform: scale(1);
}
.hfa-scale:hover, .hfa-scale:focus {
  transform: scale(1.021121);
}
.title1 {
  font-size: 32px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #fcfcfc;
}

.title2 {
  font-size: 32px;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
}

.title3 {
  font-weight: 900;
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;
  color: inherit;
}

.title4 {
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  color: inherit;
}

.descr {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 54px;
}

.cookie-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.2;
}

.disc {
  list-style: disc;
  padding-left: 20px;
}

.decimal {
  list-style: decimal;
  padding-left: 20px;
}

.arrow-up {
  position: fixed;
  bottom: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 30%;
  color: #016d62;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
.arrow-up:hover, .arrow-up:focus {
  color: #003436;
  transform: scale(1.03112);
}
.arrow-up:active {
  color: #016d62;
  transform: inherit;
}
.arrow-up svg {
  width: 3.5rem;
  height: 3.5rem;
}
.arrow-up.show {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.just-validate-error-field.form-input {
  border: 1px solid #9b0a00;
}

.just-validate-error-label {
  display: none;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.header.fixed {
  z-index: 5;
  background: #003436;
  -webkit-box-shadow: 0px 3px 15px 0px rgba(4, 4, 4, 0.75);
  -moz-box-shadow: 0px 3px 15px 0px rgba(4, 4, 4, 0.75);
  box-shadow: 0px 3px 15px 0px rgba(4, 4, 4, 0.75);
}
.header .navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding-top: 17px;
  padding-bottom: 17px;
}
.header ul {
  display: none;
}
@media screen and (min-width: 1200px) {
  .header ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
}
.header .open-btn {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}
@media screen and (min-width: 1200px) {
  .header .open-btn {
    display: none;
  }
}

.footer {
  padding-top: 80px;
  padding-bottom: 80px;
  background: #003436;
  color: #fcfcfc;
}
.footer .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
@media screen and (min-width: 1200px) {
  .footer .content-wrapper {
    align-items: stretch;
  }
}
.footer .navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  text-align: center;
}
@media screen and (min-width: 1200px) {
  .footer .navigation {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.footer .navigation .logo {
  align-self: center;
}
@media screen and (min-width: 1200px) {
  .footer .navigation .logo {
    align-self: flex-start;
  }
}
.footer .navigation ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 1200px) {
  .footer .navigation ul {
    justify-content: space-between;
    align-items: flex-end;
  }
}
.footer .navigation .link {
  background: transparent;
  color: #fcfcfc;
}
.footer .lower {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media screen and (min-width: 1200px) {
  .footer .lower {
    flex-direction: row;
    justify-content: space-between;
  }
}
.footer .lower ul {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.hero {
  padding-top: 0;
  padding-bottom: 0;
  background: #003436;
  color: #fcfcfc;
}
.hero .container {
  max-width: 1200px;
  padding: 0;
}
@media screen and (min-width: 1200px) {
  .hero .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}
.hero .text-wrapper {
  padding: 20px;
  padding-top: 100px;
  padding-bottom: 40px;
  width: 100%;
  max-width: 430px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media screen and (min-width: 768px) {
  .hero .text-wrapper {
    max-width: 768px;
  }
}
@media screen and (min-width: 1200px) {
  .hero .text-wrapper {
    padding-top: 0;
    margin: 0;
  }
}
.hero img {
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .hero img {
    max-width: 622px;
  }
}

.skyscan {
  padding-top: 0;
  padding-bottom: 0;
}
.skyscan .container {
  padding: 0;
  max-width: 100%;
}
.skyscan .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  color: #fcfcfc;
  text-align: center;
  background-image: url("../img/2.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 600;
}
@media screen and (min-width: 1200px) {
  .skyscan .content-wrapper {
    min-height: 700px;
  }
}

.planer ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.planer li {
  background: #e9f9ff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #016d62;
}
@media screen and (min-width: 1200px) {
  .planer li {
    flex-direction: row;
    align-items: center;
  }
  .planer li:nth-child(even) {
    flex-direction: row-reverse;
    background: #016d62;
    color: #fcfcfc;
  }
}
.planer li .text-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media screen and (min-width: 1200px) {
  .planer li .text-wrapper {
    max-width: 50%;
  }
}
.planer li img {
  width: 100%;
}

.casinos ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  width: 100%;
}
.casinos li {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .casinos li {
    max-width: 353px;
  }
}
.casinos .btn-second {
  margin: 0 auto;
  margin-top: 50px;
}

.contacts p {
  margin-top: 30px;
  margin-bottom: 20px;
}
.contacts .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
@media screen and (min-width: 1200px) {
  .contacts .content-wrapper {
    flex-direction: row;
    align-items: stretch;
  }
}
.contacts img {
  width: 100%;
  max-width: 590px;
}

.privacy {
  background-color: transparent;
  color: #003436;
}

.success {
  color: #fcfcfc;
  background-color: #003436;
  text-align: center;
}
.success .content-wrapper {
  min-height: calc(100vh - 275px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 590px;
  margin: 0 auto;
  background: transparent;
}
@media screen and (min-width: 1200px) {
  .form {
    margin: 0;
  }
}
.form-label {
  display: inline-block;
  width: 100%;
}
.form-input {
  width: 100%;
  border: 1px solid #016d62;
  border-radius: 20px;
  padding: 15px 30px;
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  background: rgba(214, 214, 214, 0.2);
  color: #fcfcfc;
}
.form-input::placeholder {
  color: #b6b6b6;
}
.form .textarea {
  min-height: 150px;
  border-radius: 26px;
  resize: none;
}
.form .btn {
  margin-top: 20px;
  max-width: 100%;
}

/* Стили для модального окна */
.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 10;
}

.modal.active {
  opacity: 1;
  visibility: visible;
}

/* Контент модального окна */
.modal-content {
  position: relative;
  background: #003436;
  padding: 40px 20px;
  color: #fcfcfc;
  width: 100%;
  max-width: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media screen and (min-width: 1200px) {
  .modal-content {
    padding: 40px 60px;
  }
}
.modal-content .container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
@media screen and (min-width: 1200px) {
  .modal-content .container {
    flex-direction: row;
  }
}

.btn-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}
.btn-wrapper .btn {
  font-family: inherit;
  background: #016d62;
  width: 100%;
  max-width: 100%;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .btn-wrapper .btn {
    max-width: max-content;
  }
}

/* Кнопка закрытия */
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 44px;
  cursor: pointer;
}

.burger.menu-container {
  position: relative;
}
.burger .menu-toggle {
  font-size: 18px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  color: #fff;
}
.burger .menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 12;
}
.burger .menu-content {
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.burger .close-menu {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 18px;
  background: none;
  border: none;
  cursor: pointer;
}
.burger .menu-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.burger ul {
  margin-top: 50px;
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.burger .btn {
  max-width: 100%;
  margin-top: auto;
}
.burger .logo {
  color: #016d62;
}