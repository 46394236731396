.hero {
    padding-top: 0;
    padding-bottom: 0;
    background: $second;
    color: $white;

    .container{
        max-width: 1200px;
        padding: 0;
        @include desktop{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            
        }
    }

    .text-wrapper{
        padding: 20px;
        padding-top: 100px;
        padding-bottom: 40px;
        width: 100%;
        max-width: 430px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 20px;
        @include tablet{
            max-width: 768px;
        }
        @include desktop{
            padding-top: 0;
            margin: 0;
        }
    }

    img{
        width: 100%;
        @include desktop{
            max-width: 622px;
        }
    }
}



.skyscan{
    padding-top: 0;
    padding-bottom: 0;
    .container{
        padding: 0;
        max-width: 100%;
    }
    .content-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px;
        color: $white;
        text-align: center;
        background-image: url('../img/2.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 600;
        @include desktop{
            min-height: 700px;
        }
    }

   
    
}

.planer{
  
    ul{
        display: flex;
        flex-direction: column;
        gap: 20px;

    }
    li{
        background: #e9f9ff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: $accent;
        @include desktop{
            flex-direction: row;
            align-items: center;
            &:nth-child(even){
                flex-direction: row-reverse;
                background: $accent;
                color: $white;
            }
        }
        .text-wrapper{
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            @include desktop{
                max-width: 50%;
            }
        }
        img{
            width: 100%;
        }
    }

}

.casinos{
    ul{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        gap: 20px;
        width: 100%;
    }
    li{
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        @include tablet{
            max-width: 353px;
        }
    }

    .btn-second{
        margin: 0 auto;
        margin-top: 50px;

    }
}


.contacts{
    p{
        margin-top: 30px;
        margin-bottom: 20px;
    }
    
    .content-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center ;
        gap: 30px;
        @include desktop{
            flex-direction: row;
            align-items: stretch;
        }
    }

    img{
        width: 100%;
        max-width: 590px;
    }
}


// =================== privacy\terms.. page start ================

.privacy {
    background-color: transparent;
    color: $text;
    

}


// =================== privacy page end ================


// =================== submit page start ================

.success{
    color: $white;
    background-color: $second;
    text-align: center;

    .content-wrapper{
        min-height: calc( 100vh - 275px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }


}

// =================== submit page end ================

